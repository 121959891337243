import {nonAuthenticatedRequest} from "../../../utils/axios/commonAxios";


export const fetchPublicUniDataAPIRequest = async <T>(publicKey: string) => {

    return await nonAuthenticatedRequest<T>(
        `${process.env.REACT_APP_API_URL}/buddy/api/public/university/`,
        "get",
        {
            headers: {"pKey": publicKey}
        }
    )
};



