import {UserReqProps} from "../../Models/User";
import {nonAuthenticatedRequest} from "../../utils/axios/commonAxios";
import {PersonalInformationProps} from "../../Models/PersonalInformationProps";


export const loginAPIRequest = async (user: UserReqProps) => {

    return await nonAuthenticatedRequest<PersonalInformationProps>(
        `${process.env.REACT_APP_KEYCLOAK_API_URL}/realms/uni-stage/protocol/openid-connect/token`,
        "post",
        {
            data: new URLSearchParams(user),
            headers: {}
        },
    )
};
