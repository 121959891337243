import {authenticatedRequest} from "../../../../utils/axios/commonAxios";

export type MessageReadProps = {
    'buddyID': string
}


export const readMessageRequest = async <T>(message:MessageReadProps) => {

    return await authenticatedRequest<T>(
        `${process.env.REACT_APP_API_URL}/buddy/api/chat/users/${message.buddyID}/ack`,
        "get",
        {}
    )
};
