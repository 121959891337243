import React from 'react';
import { Modal, Button, Row, Col } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { AiOutlineEye } from 'react-icons/ai';
import { GooglePlayButton, AppStoreButton, ButtonsContainer } from 'react-mobile-app-button';

const RegisterSuccessModel = ({ uniPublicData, logout, visible }) => {
  const APKUrl = 'https://play.google.com/store/apps/details?id=io.univiser.mobile.v2&hl=en&gl=US';
  const iOSUrl = 'https://apps.apple.com/lk/app/univiser-buddy/id6470714839';

  return (
    <div className="registerSuccessModel">
      <Modal title="" centered visible={visible} footer={null}>
        <Row>
          <Col>
            <CheckCircleFilled className="check_icon" />
          </Col>
        </Row>
        <h2>You have successfully completed your profile</h2>
        <p>Your profile is under admin review :)</p>
        <div className="border_box">
          <AiOutlineEye className="eye_icon" />
          <span className="text">
            Your profile will be visible on{' '}
            <a href={`https://${uniPublicData?.domain}`} target="_blank" rel="noopener noreferrer">
              https://{uniPublicData?.domain}/
            </a>{' '}
            once the admin approves
          </span>
        </div>
        <Button type="primary" onClick={logout} style={{ marginBottom: '20px' }}>
          Login to my account
        </Button>

        <div>
          <p style={{ color: 'black', margin: '0', padding: '0', fontSize: '16px' }}>
            Don't forget to download the Univiser Ambassador
          </p>
          <p style={{ color: 'black', margin: '0', padding: '0', fontSize: '16px' }}>Mobile App</p>
          <div style={{ display: 'flex', marginTop: '5px', alignItems: 'center', justifyContent: 'center' }}>
            <ButtonsContainer>
              <GooglePlayButton url={APKUrl} theme={'dark'} className={'custom-style'} />
              <AppStoreButton url={iOSUrl} theme={'dark'} className={'custom-style'} />
            </ButtonsContainer>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RegisterSuccessModel;
