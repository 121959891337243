import {authenticatedRequest} from "../../../utils/axios/commonAxios";

export type GetBuddlistProps = {
    "message": string
    "email": string
    "phone": string
    "name": string
    "channel": "EMAIL" | "SMS" | 'APP' | 'WhatsApp'
}


export const resolveAndCloseRequest = async <T>(requestProps) => {

    return await authenticatedRequest<T>(
        `${process.env.REACT_APP_API_URL}/buddy/api/chat/users/${requestProps.id}/status`,
        "post",
        {
            data: {
                "message": "-",
                "status": requestProps.status
            },
        }
    )
};

