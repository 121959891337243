export type MessageProps = {
    'buddyID': string
}


export const getAllAttachmentRequest = async (message: MessageProps) => {
    // let userDetails = JSON.parse(<string>localStorage.getItem("token"));

    let sessionDetails = JSON.parse(localStorage.getItem('user') as string)
    return await fetch(
        `${process.env.REACT_APP_API_URL}/buddy/api/chat/users/${message.buddyID}/history/media`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',

                Authorization: `Bearer ${sessionDetails?.access_token}`,
            },
        }
    )
        .then((res) => res.json())
};
