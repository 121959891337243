export const getChatAreaRequest = async (user: { id: string }) => {
    // let userDetails = JSON.parse(<string>localStorage.getItem("token"));
    // console.log("user",user)
    let sessionDetails = JSON.parse(localStorage.getItem('user') as string)
    return await fetch(
        `${process.env.REACT_APP_API_URL}/buddy/api/chat/users/${user.id}/history`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',

                Authorization: `Bearer ${sessionDetails?.access_token}`,
            }
        }
    )
        .then((res) => res.json())
};
