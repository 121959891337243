export type MessageProps = {
    "message": string
    'buddyID': string
}


export const sendMessageRequest = async (message: MessageProps) => {
    // let userDetails = JSON.parse(<string>localStorage.getItem("token"));

    let sessionDetails = JSON.parse(localStorage.getItem('user') as string)
    return await fetch(
        `${process.env.REACT_APP_API_URL}/buddy/api/chat/users/${message.buddyID}/chat`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',

                Authorization: `Bearer ${sessionDetails?.access_token}`,
            },
            body: JSON.stringify({
                message: message.message
            })
        }
    )
        .then((res) => res.json())
};
