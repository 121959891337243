import {authenticatedRequest, GetToken} from "../../../../utils/axios/commonAxios";

export type GetBuddlistProps = {
    "message": string
    "email": string
    "phone": string
    "name": string
    "channel": "EMAIL" | "SMS" | 'APP' | 'WhatsApp'
}


export const getMyStatsRequest = async <T>(filterOptions) => {

    let filterParam = "";
    if(filterOptions?.length>=2){
        filterParam = `start-date=${filterOptions[0]}&end-date=${filterOptions[1]}`;
    }
    return await authenticatedRequest<T>(
        `${process.env.REACT_APP_API_URL}/buddy/api/dashboard/dashboard-count-summary?${filterParam}`,
        "get",
        {
            headers: {
                Authorization: `Bearer ${GetToken().access_token}`,
                ContentType: 'application/json'

            }
        }
    )
};
