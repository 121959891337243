import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { AuthPropType, useAuth } from "../AuthProvider/AuthoProvider";
import { useEffect } from "react";
import MainLayout from "../../layout/MainLayout";
import UnderReviewComponent from "../underReviewComponent";
import { Header } from "antd/lib/layout/layout";
import MainHeader from "../../layout/Header/MainHeader";
import { useAppDispatch } from "../../app/hooks";
import { getSystemData } from "./SystemDataSlice";
import logo from "../../../assets/images/logo.png";
import { setPrivateRouteData } from "./PrivateRoutesDataSlice";

const PrivateRoutes = () => {
  const location = useLocation();
  let { user }: AuthPropType = useAuth();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSystemData());
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedUser = searchParams.get("user");
    const isRouted = searchParams.get("routed");
    if (location?.pathname === "/chat" && selectedUser && !isRouted) {
      dispatch(setPrivateRouteData(selectedUser));
    }
  }, [location]);

  useEffect(() => {
    // if (!user) {
    //   navigate("/");
    // }
  }, [user]);

  return user ? (
    <MainLayout>
      <Header className="header">
        <MainHeader />
      </Header>
      <UnderReviewComponent />
      <Outlet />
    </MainLayout>
  ) : (
    <Navigate to="login" />
  );
};
export default PrivateRoutes;
