const axios = require('axios').default;

export const fetchStatsAPIRequest = async (token: string) => {
    const data = await axios.get(`${process.env.REACT_APP_API_URL}/buddy/api/university/user/stats`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            // 'Origin':"http://localhost:3000",
            // 'Access-Control-Request-Method':'GET'
        }
    })
    return data.data;

};
