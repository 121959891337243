import {authenticatedRequest} from "../../../utils/axios/commonAxios";


export const getKnowledgeBaseItemByIdAPIRequest = async <T>(
    updateItem: { itemId: number }
) => {
    return await authenticatedRequest<T>(
        `${process.env.REACT_APP_API_URL}/buddy/api/user/knowledge/items/system/${updateItem.itemId}`,
        "get",
        {}
    )
};


export const getKnowledgeBaseSavedItemByIdAPIRequest = async <T>(
    updateItem: { itemId: number }
) => {
    return await authenticatedRequest<T>(
        `${process.env.REACT_APP_API_URL}/buddy/api/user/knowledge/items/${updateItem.itemId}`,
        "get",
        {}
    )
};

