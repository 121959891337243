// MultiSelectDropdown.tsx
import React, { useState, useEffect, useRef } from 'react';
import { Checkbox, Dropdown, Button, Menu, Input } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import './customMultiSelect.scss';

interface OptionType {
  label: string;
  value: CheckboxValueType;
}

interface MultiSelectDropdownProps {
  options: OptionType[];
  onChange: (selectedValues: CheckboxValueType[]) => void;
  value: CheckboxValueType[];
  maxDisplayLength?: number;
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  options = [],
  onChange,
  value = [],
  maxDisplayLength = 26,
}) => {
  const [selectedValues, setSelectedValues] = useState<CheckboxValueType[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<OptionType[]>(options);
  const [visible, setVisible] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedValues(value);
  }, [value]);

  useEffect(() => {
    const lowerSearchText = searchText?.toLowerCase();
    const filtered: any =
      options?.length > 0 && options?.filter((option) => option?.label?.toLowerCase().includes(lowerSearchText));
    setFilteredOptions(filtered);
  }, [options, searchText]);

  const handleMenuClick = (e: any) => {
    const value = e.key;
    let newSelectedValues = [...selectedValues];

    if (value === 'undecided') {
      newSelectedValues = newSelectedValues.includes(0) ? [] : [0];
    } else {
      const index = newSelectedValues.indexOf(value);
      if (index === -1) {
        newSelectedValues.push(value);
      } else {
        newSelectedValues.splice(index, 1);
      }

      const undecidedIndex = newSelectedValues.indexOf(0);
      if (undecidedIndex !== -1 && newSelectedValues.length > 1) {
        newSelectedValues.splice(undecidedIndex, 1);
      }
    }

    setSelectedValues(newSelectedValues);
    onChange(newSelectedValues);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleVisibleChange = (isVisible: boolean) => {
    setVisible(isVisible);
  };

  const handleDocumentClick = (e: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
      setVisible(false);
    }
  };

  const renderButtonText = () => {
    if (selectedValues.length === 0) {
      return 'Select courses';
    }

    const hasUndecided = selectedValues.includes(0);

    if (hasUndecided) {
      return 'Undecided';
    }

    const displayText = options
      .filter((option) => selectedValues.includes(String(option.value)))
      .map((selectedOption) => selectedOption.label)
      .join(', ');

    if (displayText.length <= maxDisplayLength) {
      return displayText;
    }

    return `${displayText.slice(0, maxDisplayLength)}...`;
  };

  const menu = (
    <div ref={dropdownRef}>
      <Menu onClick={handleMenuClick}>
        <Menu.Item>
          <Input placeholder="Search options" value={searchText} onChange={handleSearchChange} />
        </Menu.Item>
        <Menu.Item key="undecided" style={{ borderBottom: '1px solid #b1b1b1' }}>
          <Checkbox
            checked={selectedValues.includes(0)}
            disabled={selectedValues.length > 0 && !selectedValues.includes(0)}
          >
            {'Undecided'}
          </Checkbox>
        </Menu.Item>
        {filteredOptions?.length > 0 &&
          filteredOptions?.map((option) => (
            <Menu.Item key={option?.value as React.Key}>
              <Checkbox
                disabled={selectedValues.includes(0)}
                checked={selectedValues.includes(String(option?.value))}
                onChange={() => {}}
              >
                {option?.label}
              </Checkbox>
            </Menu.Item>
          ))}
      </Menu>
    </div>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
      visible={visible}
      onVisibleChange={handleVisibleChange}
      className="multiple-select-button"
    >
      <Button>
        {renderButtonText()} <i className="fa fa-caret-down" />
      </Button>
    </Dropdown>
  );
};

export default MultiSelectDropdown;
