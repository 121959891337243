const axios = require('axios').default;

export const forgotPasswordGetLinkAPIRequest = async (email: string) => {
    const data = await axios.post(`${process.env.REACT_APP_API_URL}/buddy/api/public/forgot-password`,
        {
            'email': email
        },
        {}
    )
    return data.data;
}
