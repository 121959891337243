import moment from 'moment-timezone';

const shortDateFormat = 'YYYY:DD:MM h:mm A';
const dateFormat = 'MMMM Do YYYY';
const dateFormatWithTime = 'MMMM Do YYYY, h:mm:ss a';
const standardDateFormat = 'DD/MM/YYYY';
const standardDateFormat2 = 'DD/MM/YYYY';
const dateAndMonth = 'YYYY MMMM';
const yearOnly = 'YYYY';
const chatMessageDateFormat = 'MMM Do YYYY, hh:mm A';

//date object format to standard way
export const dateFormatWithDate = (date) => {
  return moment(date).format(dateFormat);
};

export const dateFormatWithTimeChange = (date) => {
  return moment(date).format(dateFormatWithTime);
};

export const shortDateFormatWithDate = (date) => {
  return moment(date).format(shortDateFormat);
};

export const standardDateFormatter = (date) => {
  return moment(date).format(standardDateFormat);
};

export const yearAndMonthFormatter = (date) => {
  return moment(date).format(dateAndMonth);
};

export const yearFormatter = (date) => {
  return moment(date).format(yearOnly);
};

export const formattedDate = (date) => {
  return moment(date, standardDateFormat2).toDate();
};

export const changeTimeToCurrentTimezone = (date, minOffset) => {
  if (minOffset) {
    let currentOffset = moment().utcOffset();
    let tempTime = new Date(date).getTime() + (-minOffset + currentOffset) * 60000;

    return moment(tempTime).format(chatMessageDateFormat);
  }

  return moment(date).format(chatMessageDateFormat);
};

export const getHourDifferent = (startTime, endTime, minOffset) => {
  const msgTime = changeTimeToCurrentTimezone(endTime, minOffset);
  const msgTimeMill = moment(msgTime, chatMessageDateFormat).valueOf();

  let timeDefMill = startTime - msgTimeMill;

  return timeDefMill / (60 * 60 * 1000);
};

export const minutesFormatter = (time) => {
  const days = Math.floor(time / 1440);
  const hours = Math.floor((time % 1440) / 60);
  const minutes = Math.floor(time % 60);

  return `${days > 0 ? days + (days === 1 ? ' day' : ' days') : ''} ${
    hours > 0 ? hours + (hours === 1 ? ' hour' : ' hours') : ''
  } ${minutes > 0 ? minutes + ' mins' : ''}`;
};
