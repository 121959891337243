import { AddKnowledgeBaseProps } from "../../Models/KnowledgeBaseProps";
import { authenticatedRequest } from "../../utils/axios/commonAxios";

const axios = require("axios").default;

export const getKnowledgeBaseItemsAPIRequest = async <T>() => {
  return await authenticatedRequest<T>(`${process.env.REACT_APP_API_URL}/buddy/api/user/knowledge/items`, "get", {});
};

export const addKnowledgeBaseItemAPIRequest = async <T>(addItem: { item: AddKnowledgeBaseProps }) => {
  return await authenticatedRequest<T>(
    `${process.env.REACT_APP_API_URL}/buddy/api/university/knowledge/items`,
    "post",
    { data: { ...addItem?.item } }
  );
};

export const deleteKnowledgeBaseItemAPIRequest = async (deleteItem: { itemId: number; token: string }) => {
  const data = await axios.delete(
    `${process.env.REACT_APP_API_URL}/buddy/api/university/knowledge/items/${deleteItem.itemId}`,
    {
      headers: {
        Authorization: `Bearer ${deleteItem.token}`,
      },
    }
  );
  return data.data;
};

export const uploadPDFAPIRequest = async (uploadPDF: { pdf: FormData; token: string }) => {
  const data = await axios.post(`${process.env.REACT_APP_API_URL}/buddy/api/settings/file/upload`, uploadPDF.pdf, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${uploadPDF.token}`,
    },
  });
  return data.data;
};

export const editKnowledgeBaseItemAPIRequest = async <T>(editItem: { item: AddKnowledgeBaseProps }) => {
  return await authenticatedRequest<T>(
    `${process.env.REACT_APP_API_URL}/buddy/api/university/knowledge/items/${editItem?.item?.id}`,
    "put",
    { data: { ...editItem?.item } }
  );
};
