import React, { useState } from 'react';
import { Button, Card, Col, Tooltip } from 'antd';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import CommonModal from '../../../components/CommonModal/CommonModal';
import { Link, useNavigate } from 'react-router-dom';

const StatTile = ({ title, subTitle, btnContent = '', icon, modal = {}, navigatePage = '', toolTip = '' }) => {
  const navigate = useNavigate();
  const [visibility, setVisibility] = useState(false);

  const handleVisible = () => {
    setVisibility(!visibility);
  };

  const handleClick = () => {
    navigatePage === 'open' ? handleVisible() : navigate(navigatePage);
  };

  return (
    <div>
      {' '}
      {/* Add a parent element */}
      <div className="stat_single" onClick={handleClick}>
        <Card className="card">
          <div className="stat_card_title">
            {title} {icon}
          </div>
          <p className="details">{subTitle}</p>
          <div className="row">
            {/* {btnContent === '' ? (
              <></>
            ) : (
              <>
                {navigatePage === 'open' ? (
                  <Button onClick={() => setVisibility(!visibility)}>{btnContent}</Button>
                ) : (
                  <Link to={navigatePage}>
                    <Button>{btnContent}</Button>
                  </Link>
                )}
              </>
            )} */}
            <Tooltip title={toolTip ? toolTip : ''}>
              <AiOutlineInfoCircle className="info_icon" />
            </Tooltip>
          </div>
        </Card>
      </div>
      <CommonModal visibility={visibility} title={subTitle} component={modal} setVisibility={setVisibility} />
    </div>
  );
};

export default StatTile;
