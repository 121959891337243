import {ProfilePhotoUpdateProps, UploadImageProps} from "../../Models/UploadImageProps";
import {authenticatedRequest} from "../../utils/axios/commonAxios";
import {UpdateImageProps} from "../../Models/UpdatePersonalInfoProps";

const axios = require('axios').default;

export const uploadImageAPIRequest = async (uploadImage: UploadImageProps) => {
    console.log(uploadImage.formData)
    const data = await axios.post(`${process.env.REACT_APP_API_URL}/buddy/api/user/upload`,
        uploadImage.formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    return data.data;
};

export type updateProfileImagesType = {
    "coverImageUrl"?: string,
    "avatarIconUrl"?: string
}

export const updateCoverImageAPIRequest = async (updateCover: updateProfileImagesType) => {
    return await authenticatedRequest<UpdateImageProps>(
        `${process.env.REACT_APP_API_URL}/buddy/api/user/profile`,
        "put",
        {
            data: updateCover
        },
    )
}

export const updateProfilePhotoAPIRequest = async (
    updateProfilePhoto: { token: string, profilePhoto: ProfilePhotoUpdateProps }
) => {
    const data = await axios.put(`${process.env.REACT_APP_API_URL}/buddy/api/university/user/`,
        updateProfilePhoto.profilePhoto,
        {
            headers: {
                'Authorization': `Bearer ${updateProfilePhoto.token}`
            }
        })
    return data.data;
};
