import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getChatSummaryRequest } from '../../../api/ChatApi';
import { RootState } from '../../../app/store';

// Define a type for the slice state
interface CounterState {
  isSummaryFetching: boolean;
  summaryStatus: 'IDLE' | 'PENDING' | 'SUCCESS' | 'FAILED';
  summaryDetails: any;
}

// Define the initial state using that type
const initialState: CounterState = {
    isSummaryFetching: false,
    summaryStatus: 'IDLE',
  summaryDetails: {},
};

export const getChatSummaryByPublishedId = createAsyncThunk('get/summary', async (user: { id: string }) => {
  return await getChatSummaryRequest(user);
});

const chatSummarySlice = createSlice({
  name: 'fetchSummaryByPublishedId',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getChatSummaryByPublishedId.pending, (state) => {
        state.summaryStatus = 'PENDING';
        state.isSummaryFetching = true;
        state.summaryDetails = {};
      })
      .addCase(getChatSummaryByPublishedId.fulfilled, (state, action) => {
        // Add user to the state array
        state.summaryStatus = 'SUCCESS';
        state.isSummaryFetching = false;
        state.summaryDetails = action.payload;
      })
      .addCase(getChatSummaryByPublishedId.rejected, (state) => {
        state.summaryStatus = 'FAILED';
        state.isSummaryFetching = false;
        state.summaryDetails = {};
      });
  },
});

export const getChatSummaryContentSelector = (state: RootState) => state.chatSummaryContent;

export default chatSummarySlice.reducer