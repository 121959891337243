import { Button, Col, Layout, Modal, Row, Tabs, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AiOutlinePlus } from 'react-icons/ai';
import { Footer, Header } from 'antd/lib/layout/layout';
import CustomModal from './addLead/CustomModal';

import logo from '../../assets/images/logo.png';
import ReferralLeadList from './referralLeadList/ReferralLeadList';
import { AddNewLead } from './addLead/AddNewLead';

const { Title } = Typography;
const MyReferral = () => {
  const [showAddModel, setShowAddModel] = useState(false);

  const handleCancel = () => {
    setShowAddModel(false);
  };

  const handleVisible = () => {
    setShowAddModel(false);
  };

  return (
    <>
      <Layout className="page_layout">
        <Content className="my_dashboard">
          <Row className="title_area">
            <Col className="col_left">
              <Title level={3}>My Referrals</Title>
            </Col>

            <Col className="col_right">
              <Button
                className="add_button"
                onClick={() => {
                  setShowAddModel(!showAddModel);
                  // setEditData({});
                }}
              >
                <AiOutlinePlus /> Add New
              </Button>
            </Col>
          </Row>
          <ReferralLeadList />
        </Content>
      </Layout>

      <CustomModal isOpen={showAddModel} onClose={() => setShowAddModel(false)} title="Add new lead">
        <AddNewLead handleCancel={() => setShowAddModel(false)} handleVisible={handleVisible} />
      </CustomModal>

      {/* <Footer className="footer">
        <span>Powered by</span>
        <img src={logo} alt="Univiser Logo" />
      </Footer> */}
    </>
  );
};

export default MyReferral;
