import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  emailChatUserId: null,
};

const privateRoutesDataSlice = createSlice({
  name: "privateRoutesData",
  initialState,
  reducers: {
    setPrivateRouteData: (state, action) => {
      state.emailChatUserId = action.payload;
    },
    clearPrivateRouteData: (state) => {
      state.emailChatUserId = null;
    },
  },
});

export const { setPrivateRouteData, clearPrivateRouteData } = privateRoutesDataSlice.actions;
export default privateRoutesDataSlice.reducer;
