import { authenticatedRequest } from "../../utils/axios/commonAxios";
import { AddPostProps, EditPostProps, DeletePostProps } from "./common";

export const getPostsAPIRequest = async <T>() => {
  return await authenticatedRequest<T>(`${process.env.REACT_APP_API_URL}/buddy/api/post/list`, "get", {});
};

export const addPostAPIRequest = async <T>(addItem: { item: AddPostProps }) => {
  return await authenticatedRequest<T>(`${process.env.REACT_APP_API_URL}/buddy/api/post`, "post", {
    data: { ...addItem?.item },
  });
};

export const editPostAPIRequest = async <T>(editItem: { item: EditPostProps }) => {
  return await authenticatedRequest<T>(`${process.env.REACT_APP_API_URL}/buddy/api/post`, "put", {
    data: { ...editItem?.item },
  });
};

export const deletePostAPIRequest = async <T>(deleteItem: { item: DeletePostProps }) => {
  return await authenticatedRequest<T>(
    `${process.env.REACT_APP_API_URL}/buddy/api/post/${deleteItem?.item?.id}`,
    "delete",
    {}
  );
};
