import React from "react";
import {Content} from "antd/es/layout/layout";
import {Row} from "antd";
import LeaderBoard from "./LeaderBoard/LeaderBoard";
import MyStatView from "./MyStatView/MyStatView";


const LeaderBoardSection = () => {

    return <Content className="my_dashboard">
        <Row className="leaderboard_stat_area">
            <LeaderBoard/>
            <MyStatView/>
        </Row>
    </Content>


}

export default LeaderBoardSection;
