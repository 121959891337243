import {ResetPasswordProps} from "../../Models/ResetPasswordProps";

const axios = require('axios').default;

export const resetPasswordAPIRequest = async (resetPassword: ResetPasswordProps) => {
    const data = await axios.post(`${process.env.REACT_APP_API_URL}/buddy/api/public/reset-password`,
        {
            'password': resetPassword.password,
            "pKey": resetPassword.publicId
        },
        {}
    )
    return data.data;
}
