export default function toCamelCase(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).replace(/[\s-_](\w)/g, (match, p1) => (p1 ? p1.toUpperCase() : ''));
}

export const toCamelCaseWord = (word) => {
  if (word.length > 0) {
    return word[0].toUpperCase() + word.substring(1).toLowerCase();
  }
  return null;
};
