import React, { useEffect, useState } from 'react';
import { useLocalStorage } from '../../hooks/localStoragehook/LocalStorageHook';
import { Button, Col, Form, Input, message, Modal, Row } from 'antd';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { registerResponseSelector } from './Register/RegisterSlice';
import { clearVerifyMobileData, verifyMobile, verifyMobileResponseSelector } from './mobile/VerifyMobileSlice';
import { CheckCircleFilled } from '@ant-design/icons';
import { AiOutlineEye } from 'react-icons/ai';
import { AuthPropType, useAuth } from '../../components/AuthProvider/AuthoProvider';
import './style.scss';
import { GooglePlayButton, AppStoreButton, ButtonsContainer } from 'react-mobile-app-button';
import { checkMobile } from './mobile/CheckMobileSlice';

const APKUrl = 'https://play.google.com/store/apps/details?id=io.univiser.mobile.v2&hl=en&gl=US';
const iOSUrl = 'https://apps.apple.com/lk/app/univiser-buddy/id6470714839';

export default function OTPForm({
  setCurrent,
  publicId,
  mobileNo,
  uniPublicData,
  formData,
}: {
  setCurrent: (number: number) => void;
  publicId: string;
  mobileNo: any;
  uniPublicData: any;
  formData: any;
}) {
  const [userData, setUserData] = useLocalStorage('Onboard', null);
  const [otp, setOTP] = useState('');
  const dispatch = useAppDispatch();
  const { registerForm } = useAppSelector(registerResponseSelector);
  const { verifyMobileStatus } = useAppSelector(verifyMobileResponseSelector);
  const [openSucessModal, setOpenSuccessModal] = useState<boolean>(false);
  const { logout }: AuthPropType = useAuth();

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const onChange = (values: any) => {
    console.log('Change:', values.target.value);
    setOTP(values.target.value);
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    dispatch(clearVerifyMobileData());
  }, []);

  useEffect(() => {
    if (verifyMobileStatus === 'SUCCESS') {
      setOpenSuccessModal(true);
    } else if (verifyMobileStatus === 'FAILED') {
      message.error('Invalid OTP');
    }
  }, [verifyMobileStatus]);

  const resendHandler = () => {
    dispatch(
      checkMobile({
        publicId: publicId || '',
        mobileNo: mobileNo || '',
      })
    );
  };

  const handleCreateProfile = () => {
    dispatch(
      verifyMobile({
        publicId: userData?.publicId || '',
        otp: otp || '',
      })
    );
  };

  //console.log('===form data===', formData);
  //console.log('===user data===', userData);

  return (
    <div className="steps-action">
      <p>
        Hi <span>{userData?.buddyName},</span> <br />
        <span>
          We have sent you the verification code. <br />
          Please check your <span>{userData?.email}</span> or <span>{formData.mobileNumber}</span> and enter the OTP to
          verify your account.
        </span>
      </p>
      <div style={{ display: 'grid', gridTemplateColumns: '150px 100px', gap: '10px' }}>
        <div>
          <Form
            name="basic"
            wrapperCol={{ span: 24 }}
            style={{ maxWidth: 150, marginTop: '15px' }}
            layout={'vertical'}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item name="otp">
              <Input onChange={onChange} onPressEnter={handleCreateProfile} />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
              {verifyMobileStatus != 'SUCCESS' ? (
                <Button
                  className={'resendBtn'}
                  //htmlType="submit"
                  onClick={resendHandler}
                  style={{ padding: 0, margin: 0 }}
                >
                  <a>Resend code</a>
                </Button>
              ) : (
                <Button
                  type="primary"
                  //htmlType="submit"
                  disabled={verifyMobileStatus === 'SUCCESS'}
                  style={{ backgroundColor: 'green' }}
                >
                  Verified
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
        <div>
          <Button
            className="createMyProfileBtn"
            type="primary"
            disabled={otp.length === 0}
            style={{ marginTop: '15px' }}
            // onClick={() => {
            //   dispatch(
            //     verifyMobile({
            //       publicId: userData?.publicId || '',
            //       otp: otp || '',
            //     })
            //   );
            // }}
            onClick={handleCreateProfile}
          >
            Create My Profile
          </Button>
          <Button
            className={'changeMyNumberBtn'}
            color="primary"
            onClick={() => {
              setCurrent(0);
            }}
            style={{ marginTop: '25px' }}
          >
            Change Number
          </Button>
        </div>
      </div>

      <div className="modal-div">
        <Modal title="" centered visible={openSucessModal} footer={null}>
          <Row>
            <Col>
              <CheckCircleFilled className="check_icon" />
            </Col>
          </Row>
          <h2>You have successfully completed your profile</h2>
          <p>Your profile is under admin review :)</p>
          <div className="border_box">
            <AiOutlineEye className="eye_icon" />
            <span className="text">
              Your profile will be visible on{' '}
              <a href={`https://${uniPublicData?.domain}`} target="_blank" rel="noopener noreferrer">
                https://{uniPublicData?.domain}/
              </a>{' '}
              once the admin approves
            </span>
          </div>
          <Button type="primary" onClick={logout} style={{ marginBottom: '20px' }}>
            Login to my account
          </Button>

          <div>
            <p style={{ color: 'black', margin: '0', padding: '0', fontSize: '16px' }}>
              Don't forget to download the Univiser Ambassador
            </p>
            <p style={{ color: 'black', margin: '0', padding: '0', fontSize: '16px' }}>Mobile App</p>
            <div style={{ display: 'flex', marginTop: '5px', alignItems: 'center', justifyContent: 'center' }}>
              <ButtonsContainer>
                <GooglePlayButton url={APKUrl} theme={'dark'} className={'custom-style'} />
                <AppStoreButton url={iOSUrl} theme={'dark'} className={'custom-style'} />
              </ButtonsContainer>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
