import {
  AlumniUserInfo,
  BaseUserInfo,
  ParentUserInfo,
  StaffUserInfo,
  StudentUserInfo,
  UpdateInfoResponseProps,
  UpdatePersonalInfoProps,
  updateIntroVideoProps,
} from '../../../Models/UpdatePersonalInfoProps';
import { authenticatedRequest } from '../../../utils/axios/commonAxios';
import { PersonalInformationProps } from '../../../Models/PersonalInformationProps';

const axios = require('axios').default;

type UserInfo = BaseUserInfo | StudentUserInfo | AlumniUserInfo | StaffUserInfo | ParentUserInfo;

export const fetchPersonalInformationAPIRequest = async () => {
  // console.log('Fetching personal information')
  return await authenticatedRequest<PersonalInformationProps>(
    `${process.env.REACT_APP_API_URL}/buddy/api/university/user`,
    'get',
    {}
  );
};

// export const updateAPIRequest = async (user: UpdatePersonalInfoProps) => {
//     console.log("UPDATE PERSONAL INFO : ", user)
//     return await authenticatedRequest<UpdateInfoResponseProps>(
//         `${process.env.REACT_APP_API_URL}/buddy/api/university/user/`,
//         "put",
//         {
//             data: user
//         },
//     )
// }

export const updateAPIRequest = async (user: any) => {
  console.log('UPDATE PERSONAL INFO : ', user);
  return await authenticatedRequest<UpdateInfoResponseProps>(
    `${process.env.REACT_APP_API_URL}/buddy/api/university/user/`,
    'put',
    {
      data: user,
    }
  );
};

export const updateIntroVideoAPIRequest = async (data: updateIntroVideoProps) => {
  return await authenticatedRequest<updateIntroVideoProps>(
    `${process.env.REACT_APP_API_URL}/buddy/api/user/profile-video/`,
    'put',
    {
      data: data,
    }
  );
};
