import React, { useEffect, useState } from 'react';

import { Avatar, Col, Table, Typography, Tooltip, Row, Card, Button, Modal, Tabs, message } from 'antd';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdContentCopy } from 'react-icons/md';
import CustomModal from '../addLead/CustomModal';
import '../addLead/CustomModal.scss';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getAllReferralLeadList, getReferralLeadListSelector } from './ReferralListSlice';

import './referralLead.css';

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_SIZE_LIST = [10, 20];

function ReferralLeadList() {
  const dispatch = useAppDispatch();
  const prospectList: any = useAppSelector(getReferralLeadListSelector);

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  const [showModel, setShowModel] = useState(false);
  const [viewedUser, setViewedUser] = useState<any>({});

  // console.log('=== viewedUser ===', viewedUser);

  useEffect(() => {
    dispatch(
      getAllReferralLeadList({
        data: {},
        page: page - 1,
        size: pageSize,
      })
    );
  }, [page, pageSize]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Source',
      dataIndex: 'prospectChannel',
      key: 'prospectChannel',
      render: (text: string) => {
        switch (text) {
          case 'BUDDY_CREATE':
            return 'Manual';
          case 'DIRECT':
            return 'Link';
          default:
            return 'Unknown';
        }
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      dataIndex: ['view'],
      key: 'view',
      align: 'right' as 'right',
      title: '',
      render: (value: any, record: any) => (
        <>
          <Button
            className="add_button"
            onClick={() => {
              setShowModel(!showModel);
              setViewedUser(record);
            }}
          >
            View
          </Button>
        </>
      ),
    },
  ];
  // console.log('viewed user', viewedUser);

  return (
    <>
      <Col className="left_col" style={{ marginTop: '15px' }}>
        <div className="col_wrapper">
          <Table
            columns={columns}
            dataSource={prospectList?.leadList?.prospects}
            sticky
            pagination={{
              onShowSizeChange: (current, size) => {
                setPage(0);
                setPageSize(size);
              },
              onChange: (current, size) => {
                setPage(current);
                setPageSize(size);
              },
              defaultCurrent: page,
              total: prospectList?.leadList?.totalItems,
              pageSize: pageSize,
              pageSizeOptions: DEFAULT_PAGE_SIZE_LIST,
            }}
          />
        </div>
      </Col>

      {viewedUser && (
        <CustomModal
          isOpen={showModel}
          onClose={() => setShowModel(!showModel)}
          title={`${viewedUser?.name}'s information`}
        >
          <Row>
            <Col span={24}>
              <Tabs
                defaultActiveKey="1"
                items={[
                  {
                    key: '1',
                    label: 'Personal details',
                    children: (
                      <>
                        <Row>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <p className="main-text-p">First Name</p>
                            <p className="sub-text-p">{viewedUser?.name}</p>
                          </Col>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <p className="main-text-p">Email</p>
                            <p className="sub-text-p">
                              {viewedUser?.email}
                              <CopyToClipboard
                                text={viewedUser?.email}
                                onCopy={() => message.info('Email copied to clipboard')}
                              >
                                <span className="copy-icon">
                                  <MdContentCopy />
                                </span>
                              </CopyToClipboard>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <p className="main-text-p">Mobile number</p>
                            <p className="sub-text-p">
                              {viewedUser?.phone}
                              <CopyToClipboard
                                text={viewedUser?.phone}
                                onCopy={() => message.info('Mobile number copied to clipboard')}
                              >
                                <span className="copy-icon">
                                  <MdContentCopy />
                                </span>
                              </CopyToClipboard>
                            </p>
                          </Col>
                          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <p className="main-text-p">Country</p>
                            <p className="sub-text-p">{viewedUser?.countryName}</p>
                          </Col>
                        </Row>
                      </>
                    ),
                  },
                  {
                    key: '2',
                    label: 'Course details',
                    children: (
                      <>
                        <Row>
                          <Col span={12}>
                            <p className="main-text-p">Programme Interests</p>
                            <div>
                              {viewedUser?.interestProgrammes &&
                                viewedUser.interestProgrammes.map((interest: any, index) => {
                                  return <p className="sub-text-c">{interest.displayName}</p>;
                                })}
                            </div>
                          </Col>
                          <Col span={12}>
                            <p className="main-text-p">Course Interests</p>
                            <div>
                              {viewedUser?.interestCourses &&
                                viewedUser.interestCourses.map((interest: any, index) => {
                                  return <p className="sub-text-c">{interest.displayName}</p>;
                                })}
                            </div>
                          </Col>
                        </Row>
                        {/* <Row>
                          <Col>
                            <p className="main-text-p">Note to Administrator</p>
                            <p className="sub-text-p">{viewedUser?.phone}</p>
                          </Col>
                        </Row> */}
                      </>
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        </CustomModal>
      )}
    </>
  );
}

export default ReferralLeadList;
