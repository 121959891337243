export const studentPersonalInfoFields = {
  displayName: true,
  interests: true,
  languages: true,
  mobileNumber: true,
  countryCode: true,
  description: true,
  stateId: true,
};

export const studentProfFields = {
  agreement: true,
};

export const alumniProfFields = {
  role: true,
  companyName: true,
  agreement: true,
};

export const parentProfFields = {
  role: true,
  companyName: true,
  agreement: true,
};

export const staffProfFields = {
  role: true,
  department: true,
  agreement: true,
};

export const personalInfoValidation = (obj, whatsappChecked, stateEnabled) => {
  let errorObj = {};

  if (Object.keys(obj).length === 0) {
    return studentPersonalInfoFields;
  } else {
    for (let index = 0; index < Object.keys(studentPersonalInfoFields).length; index++) {
      const element = Object.keys(studentPersonalInfoFields)[index];

      if (element === 'stateId' && !stateEnabled) {
        continue;
      }

      if (obj[element]) {
        // console.log("=== have ===", obj[element]);
      } else {
        errorObj[element] = true;
      }
    }

    if (!whatsappChecked && obj?.whatsapp === obj?.mobileNumber) {
      errorObj['sameNumber'] = true;
    } else {
      if (errorObj.hasOwnProperty('sameNumber')) {
        delete errorObj['sameNumber'];
      }
    }
    return errorObj;
  }
};

export const professionInfoValidation = (obj, userType) => {
  let errorObj = {};

  if (userType === 'STUDENT') {
    if (Object.keys(obj).length === 0) {
      return studentProfFields;
    } else {
      for (let index = 0; index < Object.keys(studentProfFields).length; index++) {
        const element = Object.keys(studentProfFields)[index];
        if (obj[element]) {
        } else {
          errorObj[element] = true;
        }
      }

      return errorObj;
    }
  } else if (userType === 'ALUMNI') {
    if (Object.keys(obj).length === 0) {
      return alumniProfFields;
    } else {
      for (let index = 0; index < Object.keys(alumniProfFields).length; index++) {
        const element = Object.keys(alumniProfFields)[index];
        if (obj[element]) {
        } else {
          errorObj[element] = true;
        }
      }

      return errorObj;
    }
  } else if (userType === 'PARENT') {
    if (Object.keys(obj).length === 0) {
      return parentProfFields;
    } else {
      for (let index = 0; index < Object.keys(parentProfFields).length; index++) {
        const element = Object.keys(parentProfFields)[index];
        if (obj[element]) {
        } else {
          errorObj[element] = true;
        }
      }

      return errorObj;
    }
  } else if (userType === 'STAFF') {
    if (Object.keys(obj).length === 0) {
      return staffProfFields;
    } else {
      for (let index = 0; index < Object.keys(staffProfFields).length; index++) {
        const element = Object.keys(staffProfFields)[index];
        if (obj[element]) {
        } else {
          errorObj[element] = true;
        }
      }

      return errorObj;
    }
  }
};
