import React from 'react';

const Greeting = ({userName}: { userName: string }) => {
    const date = new Date();
    const currentHour = date.getHours();

    let greeting = '';
    if (currentHour < 12) {
        greeting = 'Good Morning';
    } else if (currentHour < 18) {
        greeting = 'Good Afternoon';
    } else {
        greeting = 'Good Evening';
    }

    return <div>{`${greeting}, ${userName}`}</div>;
};

export default Greeting;
