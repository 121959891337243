import React from 'react';
import { Route, Routes } from 'react-router-dom';
import 'antd/dist/antd.min.css';
import '../src/assets/scss/app.scss';
import LoginScreen from './screens/login/LoginScreen';
import PrivateRoutes from './components/PrivateRoutes/PrivateRoutes';
import MyDashboard from './screens/dashboard/MyDashboard';
import MyReferral from './screens/referral/MyReferral';
import { RegistrationRevamp } from './screens/register/Register/Revamp/RegistrationRevamp';
import GlobalLoader from './loadingSlice/GlobalLoader';

const ResetPasswordGetLinkScreen = React.lazy(
  () => import('./screens/resetPassword_getLink/ResetPasswordGetLinkScreen')
);
const ResetPassword = React.lazy(() => import('./screens/resetPassword/ResetPassword'));
const Chat = React.lazy(() => import('./screens/Chat/Chat'));
const KnowledgeBase = React.lazy(() => import('./screens/knowlageBaseV2/KnowledgeBase'));
const KnowledgeBaseDescription = React.lazy(
  () => import('./screens/knowledgeBaseDescription/knowledgeBaseDescription')
);
const Onboard = React.lazy(() => import('./screens/createNewPassword/OnboardScreen'));
const FAQScreen = React.lazy(() => import('./screens/faq/FAQScreen'));
const FAQDescription = React.lazy(() => import('./screens/faqDescription/FAQDescriptionScreen'));
const Register = React.lazy(() => import('./screens/register/Register/Register'));
const Profile = React.lazy(() => import('./screens/profile/ProfileScreen'));
const Posts = React.lazy(() => import('./screens/posts/viewPosts/Posts'));
const EditPost = React.lazy(() => import('./screens/posts/editPosts/EditPost'));

function App() {
  return (
    <>
      <GlobalLoader />
      <Routes>
        {/*public routes*/}
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/onboard/:id" element={<Onboard />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="/register" element={<RegistrationRevamp />} />
        <Route path="/resetPasswordGetLink" element={<ResetPasswordGetLinkScreen />} />
        <Route path="/resetspw/:id" element={<ResetPassword />} />
        {/*private Routes*/}
        <Route element={<PrivateRoutes />}>
          <Route path="/knowledgeBase" element={<KnowledgeBase />} />
          <Route path="/knowledgeBaseDescription/:id" element={<KnowledgeBaseDescription />} />
          <Route path="/faqDescription/:id" element={<FAQDescription />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/faq" element={<FAQScreen />} />
          <Route path="/dashboard" element={<MyDashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/" element={<MyDashboard />} />
          <Route path="*" element={<LoginScreen />} />
          <Route path="/posts" element={<Posts />} />
          <Route path="/editPost" element={<EditPost />} />
          <Route path="/referral" element={<MyReferral />} />
        </Route>
        {/*<Route path="/testChat" element={<TestChat/>}/>*/}
        {/*<Route path="*" element={<LoginScreen/>}>*/}
      </Routes>
    </>
  );
}

export default App;
