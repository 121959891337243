import { Button, Col, Row } from "antd";
import "./add_lead.scss";

export const Navigation = ({ currentTab, handleNext, handleBack, handleAdd, handleCancel }) => {
  return (
    <Row className="navigation-main-row">
      <Col span={4} className="cancel-col">
        {/* <Button type="ghost" className="cancel-button" onClick={handleCancel}>
          Cancel
        </Button> */}
      </Col>

      <Col span={currentTab === "1" ? 4 : 8} offset={currentTab === "1" ? 16 : 12} className="next-col">
        {currentTab === "1" ? (
          <Button className="next-button" onClick={handleNext}>
            Next
          </Button>
        ) : (
          <>
            <Button type="ghost" className="back-button" onClick={handleBack}>
              Go back
            </Button>
            <Button className="add-lead-button" onClick={handleAdd}>
              Add Lead
            </Button>
          </>
        )}
      </Col>
    </Row>
  );
};
