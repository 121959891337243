import { Button, Card, Checkbox, Col, Form as AntForm, Input, Layout, message, Typography } from "antd";
import "antd/dist/antd.min.css";
import { useEffect } from "react";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import logo from "../../assets/images/logo.png";
import { AuthPropType, useAuth } from "../../components/AuthProvider/AuthoProvider";
import { UserReqProps } from "../../Models/User";
import { composeValidators, required, validatePassword } from "../../utils/Validations/Validations";
import { clearLoginResponse, loginRequest, loginResponseSelector } from "./LoginSlice";
import "./style.scss";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { clearPrivateRouteData } from "../../components/PrivateRoutes/PrivateRoutesDataSlice";

const { Header, Content, Footer } = Layout;

function LoginScreen() {
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  let { loginIsFetching, loginResponse, loginStatus, loginError } = useAppSelector(loginResponseSelector);
  const privateRouteDataUserId = useSelector((state: RootState) => state.privateRouteData.emailChatUserId);
  const [messageApi, contextHolder] = message.useMessage();
  let { login, user }: AuthPropType = useAuth();

  const onSubmit = (userReqProps: UserReqProps) => {
    dispatch(
      loginRequest({
        client_id: "univiser-buddy",
        grant_type: "password",
        password: userReqProps.password,
        username: userReqProps.username,
      })
    );
    dispatch(clearLoginResponse());
  };

  useEffect(() => {
    if (user && privateRouteDataUserId) {
      navigate(`/chat?user=${privateRouteDataUserId}&routed=true`);
      dispatch(clearPrivateRouteData());
    } else if (user) {
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    if (loginStatus === "SUCCESS") {
      if (login) {
        login(loginResponse);
      }
    }
    dispatch(clearLoginResponse());
  }, [loginStatus]);

  useEffect(() => {
    if (loginError.error?.message != "") {
      error(loginError?.error?.message);
    }
    dispatch(clearLoginResponse());
  }, [loginError]);

  const error = (error: string) => {
    messageApi.open({
      type: "error",
      content: error,
    });
  };

  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };

  return (
    <>
      {contextHolder}
      <Layout>
        <Header className="header">
          <img className="logo" src={logo} alt="Logo" />
        </Header>
        <Content className="login_page">
          <Col>
            <Typography className="page_title">Login to Your Univiser Ambassador Portal</Typography>
            <Card className="card">
              <Form
                onSubmit={onSubmit}
                labelCol={{ span: 3, offset: 12 }}
                layout={true}
                render={({ handleSubmit, values, hasValidationErrors, errors }) => (
                  <form onSubmit={handleSubmit} className="form" encType="multipart/form-data">
                    <Field name="username" validate={composeValidators(required)}>
                      {({ input, meta, ...rest }) => (
                        <AntForm.Item
                          name="username"
                          label="Email address"
                          labelCol={{ span: 24 }}
                          labelAlign={"left"}
                          validateStatus={meta.touched && meta.error ? "error" : ""}
                          help={meta.touched && meta.error && meta.error}
                          // rules={[
                          //     {
                          //         type: 'email',
                          //         message: 'The input is not valid E-mail!',
                          //     },
                          //     {
                          //         required: true,
                          //         message: 'Please input your E-mail!',
                          //     },
                          // ]}
                        >
                          <div className="input-and-icons">
                            {/*<MdAccountBox style={{marginRight:10}}/>*/}

                            <Input
                              {...input}
                              {...meta}
                              {...rest}
                              className="input"
                              placeholder={"Enter your email address"}
                            />
                            {meta.touched && meta.error && <span></span>}
                          </div>
                        </AntForm.Item>
                      )}
                    </Field>
                    <Field name="password" validate={composeValidators(required)}>
                      {({ input, meta, ...rest }) => (
                        <AntForm.Item
                          label="Password"
                          validateStatus={meta.touched && meta.error ? "error" : ""}
                          labelCol={{ span: 24 }}
                          labelAlign={"left"}
                          help={meta.touched && meta.error && meta.error}
                          name="password"
                          rules={[{ message: "Please input your password!" }]}
                        >
                          <div className="input-and-icons">
                            {/*<MdPassword style={{marginRight:10}}/>*/}
                            <Input.Password
                              {...input}
                              {...meta}
                              {...rest}
                              placeholder="Enter your password"
                              className="input"
                            />
                            {meta.touched && meta.error && <span></span>}
                          </div>
                        </AntForm.Item>
                      )}
                    </Field>

                    <div className="check_area">
                      <div className="remember_check">
                        <Checkbox onChange={onChange}>Remember me</Checkbox>
                      </div>
                      <div className="forgot_link">
                        <a href={"resetPasswordGetLink"} className="forgot_link">
                          Forgot Password?
                        </a>
                      </div>
                    </div>

                    <AntForm.Item>
                      <Button type="primary" htmlType={"submit"}>
                        Sign In
                      </Button>
                    </AntForm.Item>
                  </form>
                )}
              />
            </Card>
          </Col>
        </Content>
        <Footer className="footer">
          <span>Powered by</span>
          <img src={logo} alt="Univiser Logo" />
        </Footer>
      </Layout>
    </>
  );
}

export default LoginScreen;
