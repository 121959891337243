export const composeValidators =
  (...validators: any) =>
  (value: any) =>
    validators.reduce((error: any, validator: (arg0: any) => any) => error || validator(value), undefined);

export const composeValdationsList = (listOfFunctions: string[]) => (value: string) => {
  if (listOfFunctions?.length === 0) {
    return undefined;
  } else {
    let listofFuc = <any>[];
    for (let i = 0; i < listOfFunctions?.length; i++) {
      if (listOfFunctions[i] === "required") {
        listofFuc.push(required);
      } else if (listOfFunctions[i] === "emptyArray") {
        listofFuc.push(emptyArray);
      } else if (listOfFunctions[i] === "validatePhone") {
        listofFuc.push(validatePhone);
      } else if (listOfFunctions[i] === "validateEmail") {
        listofFuc.push(validateEmail);
      }
    }
    return listofFuc.reduce((error: any, validator: (arg0: any) => any) => error || validator(value), undefined);
  }
};

export const required = (value: any) => (value ? undefined : "This field is required");
export const requiredInJapn = (value: any) => (value ? undefined : "必須");
export const emptyArray = (array: string) => (array != "" ? undefined : "error.validation.isEmpty");
export const isPositiveNumber = (number: number) => (number > 0 ? undefined : "error.validation.positiveNumber");
export const isOther = (number: string) => (number != "other" ? undefined : "error.validation.isEmpty");
export const skip = (val: any) => undefined;

export function shoudMiliMeter(email: string) {
  const re = /mm/;
  if (!re.test(email)) {
    //return "Value should be in mm (ex: 100mm)"
    return "error.validation.shouldMm";
  }
}

export function validateEmail(email: string) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!re.test(email)) {
    return "Please enter a valid email.";
  }
}

export function validatePhone(phoneNumber: string) {
  var phoneno = /^(\+\d{1,3}\s?)?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/g;
  if (!phoneno.test(phoneNumber)) {
    //return "is invalid"
    return "Phone number is not valid";
  }
}

export function validatePhoneWithoutCode(phoneNumber: string) {
  var phoneno = /^\d{9}$/im;
  if (!phoneno.test(phoneNumber)) {
    //return "is invalid"
    return "error.validation.isInvalid";
  }
}

export function minLengthList(tagsList: string | any[]) {
  if (tagsList.length > 10) {
    //return "more than 10 tags"
    return "error.validation.10Tags";
  } else {
    return undefined;
  }
}

export function validatePassword(email: string) {
  // const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  // if (!re.test(email)) {
  //     return "Should contain at least 8 characters with at least a symbol, upper and lower case letters and a number"
  //     // return "error.validation.password"
  // }

  const re = /^.{6,}$/;

  if (!re.test(email)) {
    return "Should contain at least 6 characters";
  }
}

// Handle HTTP errors since fetch won't.
export function handleErrors(response: { ok: any; json: () => { (): any; new (): any; message: string | undefined } }) {
  if (!response.ok) {
    // alert(JSON.stringify(response))
    throw Error(response.json().message);
  }
  return response;
}

export const validityCheck = (stat: boolean, message: string) => {
  return stat ? undefined : message;
};

export const textLimit = (inputText: string, limit: number) => {
  if (limit >= inputText.trim().length) {
    return undefined;
  } else {
    //return `Text limit is ${limit}`
    return `error.validation.limit.${limit}`;
  }
};

export const linkValidation = (inputText: string) => {
  var linkRegex =
    /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  if (!linkRegex.test(inputText)) {
    //return "Should be a link"
    return "error.validation.link";
  }
};
