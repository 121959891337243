import { UserChatType } from "../../../Models/ChatBuddyProps";
import {authenticatedRequest} from "../../../utils/axios/commonAxios";
import {BlockRequestType} from "./BlockChatSlice";

export type GetBuddlistProps = {
    "message": string
    "email": string
    "phone": string
    "name": string
    "channel": "EMAIL" | "SMS" | 'APP' | 'WhatsApp'
}


export const blockChatRequeset = async <T>(blockRequest: BlockRequestType) => {

    return await authenticatedRequest<T>(
        `${process.env.REACT_APP_API_URL}/buddy/api/chat/users/${blockRequest.id}/status`,
        "post",
        {
            data: {
                "message": blockRequest.message,
                "status": UserChatType.BLOCKED
            }
        }
    )
};

