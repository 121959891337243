import { Row, message } from 'antd';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { PersonalDetails } from './PersonalDetails';
import { CourseDetails } from './CourseDetails';
import { useEffect, useState } from 'react';
import { Navigation } from './Navigation';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { fetchUniDataRequest, fetchUniDataSelector } from '../../register/uniData/UniDataFetchingSlice';
import './add_lead.scss';
import { addLeadRequest, addLeadSelector } from './addLeadSlice';
import ReferralLeadList from '../referralLeadList/ReferralLeadList';
import { DEFAULT_PAGE_SIZE } from '../referralLeadList/ReferralLeadList';
import { getAllReferralLeadList } from '../referralLeadList/ReferralListSlice';
import { clearAddLeadResponse } from './addLeadSlice';

export const AddNewLead = ({ handleCancel, handleVisible }: any) => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  let { uniDataStatus, uniDataIsFetching, uniDataResponse } = useAppSelector(fetchUniDataSelector);
  let { addStatus, addError } = useAppSelector(addLeadSelector);
  const [currentView, setCurrentView] = useState('1');
  const [personalDetails, setPersonalDetails] = useState<any>({});
  const [courseData, setCourseData] = useState<any>({ sendEmailInvite: true });

  useEffect(() => {
    if (uniDataStatus === 'IDLE') {
      dispatch(fetchUniDataRequest());
    }
  }, [uniDataIsFetching, uniDataStatus, dispatch]);

  useEffect(() => {
    if (addStatus === 'SUCCESS') {
      message.success('Successfully added !');
      handleVisible();
    } else if (addStatus === 'FAILED') {
      message.error(addError?.message);
    }
    dispatch(clearAddLeadResponse());
  }, [addStatus, handleVisible]);

  const handleTabOnChange = (key: string) => {
    setCurrentView(key);
  };

  const handlePersonalDetailInputs = (key: string, value: any) => {
    setPersonalDetails({ ...personalDetails, [key]: value });
  };

  const handleCourseDetailInputs = (key: string, value: any) => {
    setCourseData({ ...courseData, [key]: value });
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Personal details',
      children: <PersonalDetails handlePersonalDetailInputs={handlePersonalDetailInputs} />,
    },
    {
      key: '2',
      label: 'Course details',
      children: (
        <CourseDetails
          handleCourseDetailInputs={handleCourseDetailInputs}
          courseData={courseData}
          programmes={uniDataResponse.programmes}
        />
      ),
    },
  ];

  const handleAdd = async () => {
    if (!courseData?.courseId || courseData?.courseId.length === 0) {
      message.error('Please select Courses Interests');
      return;
    }

    const formattedCourseId = courseData.courseId.join(', ');

    let obj = {
      name: personalDetails?.name,
      email: personalDetails?.email,
      mobileNumber: personalDetails?.mobileNumber,
      courseId: formattedCourseId,
      note: courseData?.note,
      countryCode: personalDetails.country,
      channels: ['EMAIL'],
      sendEmailInvite: courseData?.sendEmailInvite,
      inviteBody: courseData?.inviteBody,
    };

    // console.log('Data being sent to server:', obj);

    //dispatch(addLeadRequest(obj));
    dispatch(addLeadRequest(obj)).then(() => {
      dispatch(getAllReferralLeadList({ data: {}, page: page - 1, size: pageSize }));
    });
  };

  const handleNavigation = (direction: 'next' | 'back') => {
    if (direction === 'next' && currentView === '1') {
      setCurrentView('2');
    } else if (direction === 'back' && currentView === '2') {
      setCurrentView('1');
    }
  };

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={handleTabOnChange}
        activeKey={currentView}
        className="main-tab"
      />
      <Navigation
        currentTab={currentView}
        handleNext={() => handleNavigation('next')}
        handleAdd={handleAdd}
        handleBack={() => handleNavigation('back')}
        handleCancel={handleCancel}
      />
    </>
  );
};
