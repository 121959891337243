import {Layout} from "antd";
import React from "react";
import "antd/dist/antd.min.css";
import {useAppSelector} from "../app/hooks";
import {fetchInformationSelector} from "../screens/profile/personalInformation/PersonalInformationSlice";

import "./style.scss"

const {Header, Content, Footer, Sider} = Layout;

function UnderReviewComponent() {

    let {informationFetchingStatus, informationResponse} = useAppSelector(fetchInformationSelector);


    if (informationResponse?.status === "PENDING") {
        return (
            <div className="under_review_component">
                <h6>Your account is under review. You will be notified once the admin approves.</h6>
            </div>
        );
    } else {
        return null;
    }
}

export default UnderReviewComponent;
