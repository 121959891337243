import { Avatar, Dropdown, Menu } from 'antd';
import 'antd/dist/antd.min.css';
import { useEffect, useState } from 'react';
import { BsChatLeftText, BsWindowStack } from 'react-icons/bs';
import {
  MdLogout,
  MdOutlineArrowDropDown,
  MdOutlineDashboard,
  MdOutlineMenu,
  MdOutlinePersonOutline,
} from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AuthPropType, useAuth } from '../../components/AuthProvider/AuthoProvider';
import { createPasswordSelector } from '../../screens/createNewPassword/CreatePasswordSlice';
import {
  fetchInformationSelector,
  fetchPersonalInformationRequest,
} from '../../screens/profile/personalInformation/PersonalInformationSlice';
import { loginResponseSelector } from '../../screens/login/LoginSlice';
import defaultAvatar from '../../assets/images/avatar-default.jpg';
import { getUnreadCount, getUnReadCountSelector } from '../../components/UnreadCounts/UnReadSlice';
import logo from '../../assets/images/logo.png';
import { RiLinksFill } from 'react-icons/ri';
import { domainValidation } from '../../utils/Validations/domainValidation';
import { ReadOutlined } from '@ant-design/icons';
import { getSystemDataSelector } from '../../components/PrivateRoutes/SystemDataSlice';
import { clearGetAllBuddyList } from '../../screens/Chat/buddyList/BuddyListSlice';
import { getMyStatsListSelector } from '../../screens/dashboard/LeaderBoardSection/MyStatView/MyStatSlice';

function MainHeader({ disabled = false }: { disabled?: boolean }) {
  const [buddyName, setBuddyName] = useState<string>();
  const [avatar, setAvatar] = useState(defaultAvatar);
  const dispatch = useAppDispatch();
  const { logout }: AuthPropType = useAuth();
  let { informationFetchingStatus, informationResponse } = useAppSelector(fetchInformationSelector);
  let { status, error, isFetching, count } = useAppSelector(getUnReadCountSelector);
  let { systemData } = useAppSelector(getSystemDataSelector);

  let { loginStatus } = useAppSelector(loginResponseSelector);
  let { fetchingDataStatus, createdPassword } = useAppSelector(createPasswordSelector);
  let navigate = useNavigate();
  let { myStatList } = useAppSelector(getMyStatsListSelector);
  const totalMessages = (myStatList?.newMessageCount ?? 0) + (myStatList?.missedMessageCount ?? 0);

  useEffect(() => {
    dispatch(getUnreadCount());
  }, []);

  // To get user personal information
  useEffect(() => {
    if (informationFetchingStatus === 'IDLE') {
      dispatch(fetchPersonalInformationRequest());
    } else if (informationFetchingStatus === 'SUCCESS') {
      // console.log("informationResponse", informationResponse);
      if (informationResponse?.profile && informationResponse?.profile?.largeProfileUrl) {
        setAvatar(informationResponse?.profile?.largeProfileUrl);
        setBuddyName(informationResponse?.profile?.firstName);
      }
    }
  }, [informationFetchingStatus]);

  useEffect(() => {
    if (fetchingDataStatus === 'SUCCESS') {
      setBuddyName(createdPassword.buddyName);
      // console.log(createdPassword.buddyName);
    }
  }, [fetchingDataStatus]);

  // To get the token
  // Profile menu
  const menu = (
    <Menu className="dropdown-menu">
      <Menu.Item key="0" disabled={disabled}>
        <a href="profile" aria-disabled={disabled}>
          <MdOutlinePersonOutline />
          My Profile
        </a>
      </Menu.Item>
      <Menu.Item key="1">
        {logout && (
          <a
            onClick={() => {
              dispatch(clearGetAllBuddyList());
              logout();
            }}
          >
            <MdLogout />
            Logout
          </a>
        )}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <img
        onClick={() => {
          navigate('/dashboard');
        }}
        className="logo"
        src={logo}
        alt="Logo"
      />
      <div className="menu_area ">
        <Menu mode="horizontal" overflowedIndicator={<MdOutlineMenu className="overflowedIndicator" size={18} />}>
          <Menu.Item disabled={disabled}>
            <MdOutlineDashboard className="mainMenuIcon" />
            Dashboard
            <Link to="/dashboard" />
          </Menu.Item>
          <Menu.Item disabled={disabled}>
            <BsChatLeftText className="mainMenuIcon" />
            Chats
            {totalMessages > 0 && <span className="ant-tag unreadCount">{totalMessages}</span>}
            <Link to="/chat" />
          </Menu.Item>
          {informationResponse?.status === 'ACTIVE' && systemData?.enableFeed && (
            <Menu.Item disabled={disabled}>
              <BsWindowStack className="mainMenuIcon" />
              Posts
              {/* {count?.unreadProspects != 0 && <span className="ant-tag unreadCount">{count?.unreadProspects}</span>} */}
              <Link to="/posts" />
            </Menu.Item>
          )}

          <Menu.Item disabled={disabled}>
            <ReadOutlined className="mainMenuIcon" />
            Knowledge Base
            {count?.unreadKnowledgeItems != 0 && (
              <span className="ant-tag unreadCount">{count?.unreadKnowledgeItems}</span>
            )}
            <Link to="/knowledgeBase" />
          </Menu.Item>
          {informationResponse?.status === 'ACTIVE' && systemData?.enableReferral && (
            <Menu.Item disabled={disabled}>
              <RiLinksFill className="mainMenuIcon" />
              Referrals
              <Link to="/referral" />
            </Menu.Item>
          )}
        </Menu>

        {/* Dropdown */}
        <div className="profile_btn_area">
          <Dropdown className="profile_btn" overlay={menu} trigger={['click']}>
            <a className="ant-dropdown-link">
              <Avatar size="large" className="avatarImage" src={avatar} />
              <span className="sp_hide">{buddyName}</span>
              <MdOutlineArrowDropDown />
            </a>
          </Dropdown>
        </div>
      </div>
    </>
  );
}

export default MainHeader;
