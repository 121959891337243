import {authenticatedRequest} from "../../utils/axios/commonAxios";


export const getUnreadCountsAPI = async <T>() => {

    return await authenticatedRequest<T>(
        `${process.env.REACT_APP_API_URL}/buddy/api/university/user/unread-count`,
        "get"
    )
};
