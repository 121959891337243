import { authenticatedRequest } from '../../../utils/axios/commonAxios';

export type GetBuddlistProps = {
  message: string;
  email: string;
  phone: string;
  name: string;
  channel: 'EMAIL' | 'SMS' | 'APP' | 'WhatsApp';
};

export const getAllLeadListRequest = async <T>(parms: any) => {
  return await authenticatedRequest<T>(
    `${process.env.REACT_APP_API_URL}/buddy/api/prospect/find?page=${parms?.page}&size=${parms?.size}`,
    'post',
    parms
  );
};
