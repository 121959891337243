import {AddKnowledgeBaseProps} from "../../Models/KnowledgeBaseProps";

const axios = require('axios').default;

export const updateKnowledgeBaseItemAPIRequest = async (
    updateItem: { item: AddKnowledgeBaseProps, token: string, itemId: number }
) => {
    const data = await axios.put(
        `${process.env.REACT_APP_API_URL}/buddy/api/university/knowledge/items/${updateItem.itemId}`,
        updateItem.item,
        {
            headers: {
                'Authorization': `Bearer ${updateItem.token}`
            }
        })
    return data.data;
};

