import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { Card, Col, DatePicker, Row, Typography, Tooltip } from 'antd';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { getMyStatDetails, getMyStatsListSelector } from './MyStatSlice';
import dayjs from 'dayjs';
import { minutesFormatter } from '../../../../utils/fomatter';

const dateFormat = 'YYYY-MM-DD';
const { RangePicker } = DatePicker;

function MyStatView() {
  const dispatch = useAppDispatch();
  const { myStatList } = useAppSelector(getMyStatsListSelector);
  const [filterDate, setFilterDate] = useState([]);

  useEffect(() => {
    if (filterDate) {
      dispatch(getMyStatDetails([...filterDate?.map((date) => dayjs(date).format(dateFormat))]));
    } else {
      dispatch(getMyStatDetails([]));
    }
  }, [filterDate]);

  useEffect(() => {
    console.log('filterDate', filterDate);
  }, [filterDate]);

  return (
    <Col className="right_col">
      <Typography className="col_title">
        <Row align="middle" className="w-100">
          <Col lg={8} xs={8} className="d-flex align-items-center">
            <div>My Stats</div>
            <Tooltip title="Ambassador KPI’s">
              <AiOutlineInfoCircle className="info_icon" />
            </Tooltip>
          </Col>
          <Col lg={16} xs={16} className="right-align-md">
            <div className="filter_area">
              <RangePicker
                onChange={(e: any) => {
                  setFilterDate(e);
                }}
                format={dateFormat}
              />
              {/*<div className="help_area">*/}
              {/*    <small className="help_label">Help</small>*/}
              {/*    <img src={iconHelp} alt="" className="help_icon"/>*/}
              {/*</div>*/}
            </div>
          </Col>
        </Row>
      </Typography>
      <div className="col_wrapper">
        {/* <Card className="card">*/}
        {/*    <Typography className="stat_card_title">{myStatList?.pageViewCount}</Typography>*/}
        {/*    <p className="details">Page views</p>*/}
        {/*    <AiOutlineInfoCircle className="info_icon"/>*/}
        {/*</Card> */}
        <Card className="card">
          <Typography className="stat_card_title">{minutesFormatter(myStatList?.avgResponseTimeInMinutes)}</Typography>
          <p className="details">Average response time</p>
          <Tooltip title="Time taken to send the first message to your prospects">
            <AiOutlineInfoCircle className="info_icon" />
          </Tooltip>
        </Card>
        <Card className="card">
          <Typography className="stat_card_title">{myStatList?.responseRate} %</Typography>
          <p className="details">Response rate</p>
          <Tooltip title="Number of prospect inquiries you have replied compared to total received">
            <AiOutlineInfoCircle className="info_icon" />
          </Tooltip>
        </Card>
        <Card className="card">
          <Typography className="stat_card_title">{myStatList?.resolvedChatCount} Resovled</Typography>
          <p className="details">Resolved chats</p>
          <Tooltip title="Total number of prospect’s you’ve helped">
            <AiOutlineInfoCircle className="info_icon" />
          </Tooltip>
        </Card>
        <Card className="card">
          <Typography className="stat_card_title">{myStatList?.transferredChatCount} Prospects</Typography>
          <p className="details">Transferred to admin</p>
          <Tooltip title="Total number of prospect’s you’ve transferred to admin">
            <AiOutlineInfoCircle className="info_icon" />
          </Tooltip>
        </Card>
      </div>
    </Col>
  );
}

export default MyStatView;
