import { BlobServiceClient } from "@azure/storage-blob";

const sasToken: string =
  "?sv=2022-11-02&ss=b&srt=sco&sp=rwdlaciytfx&se=2024-09-05T23:41:50Z&st=2023-09-05T15:41:50Z&spr=https,http&sig=1nE%2FvkTAwi7qk5woSYk5oNbKYKy9y7JRqGyMW3EJdIU%3D"; // Fill string with your SAS token
// const containerName: string = `videos`;
const storageAccountName: string = "univiserstg";
const basePath: string = "univiserstg.blob.core.windows.net";

export const isStorageConfigured = () => {
  return !(!storageAccountName || !sasToken);
};

// return list of blobs in container to display
const getBlobsInContainer = async (containerClient: any, containerName) => {
  const returnedBlobUrls: any = [];

  // get list of blobs in container
  // eslint-disable-next-line
  for await (const blob of containerClient.listBlobsFlat()) {
    // if image is public, just construct URL
    returnedBlobUrls.push(`https://${basePath}/${containerName}/${blob.name}`);
  }

  return returnedBlobUrls;
};

const createBlobInContainer = async (containerClient, file, containerName) => {
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(file.name);

  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: file.type } };

  // upload file
  const res = await blobClient.uploadBrowserData(file, options);
  
  const metaRes = await blobClient.setMetadata({ UserName: "univiser" });

  if (res?._response?.status === 201) {
    return `https://${basePath}/${containerName}/${file.name}`;
  }
};

const uploadFileToBlob = async (file, containerName) => {
  if (!file) return [];

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(`https://${basePath}/?${sasToken}`);

  // get Container - full public read access
  const containerClient = blobService.getContainerClient(containerName);
  
  // upload file
  const uploadedResponse = await createBlobInContainer(containerClient, file, containerName);

  return uploadedResponse;

  // get list of blobs in container
  // return getBlobsInContainer(containerClient, containerName);
};

export default uploadFileToBlob;
