import { Spin } from 'antd';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { store } from './app/store';
import { AuthProvider } from './components/AuthProvider/AuthoProvider';
import './index.css';
import reportWebVitals from './reportWebVitals';
import i18n from './utils/i18n';
import { I18nextProvider } from 'react-i18next';
import GlobalLoader from './loadingSlice/GlobalLoader';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <AuthProvider>
        <Suspense fallback={<GlobalLoader />}>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </Suspense>
      </AuthProvider>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
