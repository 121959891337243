import React, {ReactNode} from "react";

function MainLayout({children}: { children: ReactNode }) {
    return (
        <>
            {children}
        </>
    );
}

export default MainLayout;
