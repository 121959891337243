import { AddLeadProps } from "../../../Models/AddLeadProps";
import { authenticatedRequest } from "../../../utils/axios/commonAxios";

const axios = require("axios").default;

export const addLeadAPIRequest = async (addLead: AddLeadProps) => {
  return await authenticatedRequest<AddLeadProps>(
    `${process.env.REACT_APP_API_URL}/buddy/api/prospect/add`,
    "post",
    {
      data: addLead,
    }
  );
};
