import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { AiOutlineClose } from 'react-icons/ai';

import './CustomModal.scss';

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
}

const CustomModal = ({ isOpen, onClose, children, title }: CustomModalProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return createPortal(
    <div className="custom-modal-overlay">
      <div className="custom-modal" onClick={(e) => e.stopPropagation()}>
        <div className="custom-modal-header">
          <div className="custom-modal-title">{title}</div>
          <div className="custom-modal-close" onClick={onClose}>
            <AiOutlineClose />
          </div>
        </div>
        <div className="custom-modal-content">{children}</div>
      </div>
    </div>,
    document.body
  );
};

export default CustomModal;
