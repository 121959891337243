import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import setSelectedBuddyReducer from '../screens/Chat/SetSelectBuddySlice';
import getAttachmentMessageReducer from '../screens/Chat/Attachments/SendAttachmetns/GetAllAttachmentMessageSlice';
import setFaMessageReducer from '../screens/Chat/FavMessageSwitch/FavMessageSwitchSlice';
import sendChatMessageReducer from '../screens/Chat/ChatArea/SendMessage/SendMessageSlice';
import readChatMessageReducer from '../screens/Chat/ChatArea/ReadMessage/ReadMessageSlice';
import getChatAreaContentReducer from '../screens/Chat/ChatArea/ChatAreaSlice';
import getChatSummaryContentReducer from '../screens/Chat/ChatArea/ChatSummarySlice';
import getListofChatBuddyListReducer from '../screens/Chat/buddyList/BuddyListSlice';
import getSystemDataReducer from '../components/PrivateRoutes/SystemDataSlice';
import LoginReducer from '../screens/login/LoginSlice';
import CreatePasswordReducer from '../screens/createNewPassword/CreatePasswordSlice';
import RegisterReducer from '../screens/register/Register/RegisterSlice';
import FetchUNiDataReducer from '../screens/register/uniData/UniDataFetchingSlice';
import VerifyMobileReducer from '../screens/register/mobile/VerifyMobileSlice';
import UploadImagesReducer from '../screens/ImageUpload/ImageUploadSlice';
import StatsFetchReducer from '../screens/dashboard/statsScreen/StatsSlice';
import PersonalInformationReducer from '../screens/profile/personalInformation/PersonalInformationSlice';
import CheckMobileReducer from '../screens/register/mobile/CheckMobileSlice';
import GetKnowledgeBaseReducer from '../screens/knowledgeBase/KnowledgeBaseGetDataSlice';
import AddKnowledgeBaseReducer from '../screens/knowledgeBase/AddKnowledgeBaseSlice';
import DeleteKnowledgeBaseReducer from '../screens/knowledgeBase/DeleteKnowledgeBaseSlice';
import UpdatePersonalInformationReducer from '../screens/profile/personalInformation/UpdatePersonalInfoSlice';
import GetFAQSummaryReducer from '../screens/faq/FAQGetSummarySlice';
import GetFAQDetailsReducer from '../screens/faq/GetFAQDetailsSlice';
import ResetPasswordGetLinkReducer from '../screens/resetPassword_getLink/ResetPasswordGetLinkSlice';
import ResetPassword from '../screens/resetPassword/ResetPasswordSlice';
import UploadPDFReducer from '../screens/knowledgeBase/UploadPDFSlice';
import UpdateCoverPhotoReducer from '../screens/ImageUpload/CoverPhotoUpdateSlice';
import UpdateProfilePhotoReducer from '../screens/ImageUpload/ProfilePictureUpdateSlice';
import UpdateKnowledgeBaseReducer from '../screens/knowledgeBaseDescription/KnowledgeBaseUpdateSlice';
import getAllFavMessagesReducer from '../screens/Chat/getAllStartMessages/FavMessageSwitchSlice';
import resolveandCloseReducer from '../screens/Chat/resolveandclose/ResolveAndCloseSlice';
import transferToAdminReducer from '../screens/Chat/transferToAdmin/TransfertoAdminSlice';
import blockChatReducer from '../screens/Chat/blockChat/BlockChatSlice';
import getListofLeaderBoardReducer from '../screens/dashboard/LeaderBoardSection/LeaderBoard/LeaderBoardSlice';
import getReferralLeadListReducer from '../screens/referral/referralLeadList/ReferralListSlice';
import getAllUnreadCountReducer from '../components/UnreadCounts/UnReadSlice';
import getMyStatsReducer from '../screens/dashboard/LeaderBoardSection/MyStatView/MyStatSlice';
import GetUniPublicDetailsReducer from '../screens/register/publicUniDetails/publicUniDetailsSlice';
import logger from 'redux-logger';
import getKnowledgeBaseItemsReducer from '../screens/knowledgeBaseDescription/KnowledgeBaseItem/KnowledgeBaseItemSlice';
import getSavedKnowledgeBaseItemsReducer from '../screens/knowledgeBaseDescription/KnowledgeBaseItem/SavedKnowledgeBaseItemSlice';
import EditKnowledgeBaseReducer from '../screens/knowledgeBase/EditKnowledgeBaseSlice';
import UpdateIntroVideoReducer from '../screens/profile/personalInformation/UploadIntroVideoSlice';
import PostReducer from '../screens/posts/PostSlice';
import PrivateRouteDataReducer from '../components/PrivateRoutes/PrivateRoutesDataSlice';
import AddLeadReducer from '../screens/referral/addLead/addLeadSlice';
import loadingReducer from '../loadingSlice/loadingSlice';

export const store = configureStore({
  reducer: {
    // counter: counterReducer,
    selectedBuddy: setSelectedBuddyReducer,
    getAttachmentMessage: getAttachmentMessageReducer,
    setFavMessages: setFaMessageReducer,
    getAllFavMessages: getAllFavMessagesReducer,
    sendChatMessage: sendChatMessageReducer,
    readChatMessage: readChatMessageReducer,
    chatAreaContent: getChatAreaContentReducer,
    chatSummaryContent: getChatSummaryContentReducer,
    getListofChatBuddyList: getListofChatBuddyListReducer,
    getSystemData: getSystemDataReducer,
    getAllCount: getAllUnreadCountReducer,
    getListofLeaderBoard: getListofLeaderBoardReducer,
    getListofReferralLeads: getReferralLeadListReducer,
    getMyStats: getMyStatsReducer,
    resolveandClose: resolveandCloseReducer,
    transferToAdmin: transferToAdminReducer,
    blockChat: blockChatReducer,
    login: LoginReducer,
    createPassword: CreatePasswordReducer,
    register: RegisterReducer,
    getUniPublicDetails: GetUniPublicDetailsReducer,
    fetchUniData: FetchUNiDataReducer,
    verifyMobile: VerifyMobileReducer,
    uploadImages: UploadImagesReducer,
    fetchStats: StatsFetchReducer,
    fetchPersonalInformation: PersonalInformationReducer,
    checkMobile: CheckMobileReducer,
    getKnowledgeBase: GetKnowledgeBaseReducer,
    addKnowledgeBase: AddKnowledgeBaseReducer,
    deleteKnowledgeBase: DeleteKnowledgeBaseReducer,
    updatePersonalInfo: UpdatePersonalInformationReducer,
    getFAQSummary: GetFAQSummaryReducer,
    getFAQDetails: GetFAQDetailsReducer,
    resetPasswordGetLink: ResetPasswordGetLinkReducer,
    resetPassword: ResetPassword,
    uploadPDF: UploadPDFReducer,
    updateCoverPhoto: UpdateCoverPhotoReducer,
    updateProfilePhoto: UpdateProfilePhotoReducer,
    updateKnowledgeBase: UpdateKnowledgeBaseReducer,
    getKnowledgeItemById: getKnowledgeBaseItemsReducer,
    getSavedKnowledgeItemById: getSavedKnowledgeBaseItemsReducer,
    editKnowledgeBase: EditKnowledgeBaseReducer,
    updateIntroVideo: UpdateIntroVideoReducer,
    buddyFeedData: PostReducer,
    privateRouteData: PrivateRouteDataReducer,
    addLead: AddLeadReducer,
    loading: loadingReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
