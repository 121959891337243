export const getChatSummaryRequest = async (user: { id: string }) => {

    let sessionDetails = JSON.parse(localStorage.getItem('user') as string)
    return await fetch(
        `${process.env.REACT_APP_API_URL}/buddy/api/chat/users/${user.id}/summary`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',

                Authorization: `Bearer ${sessionDetails?.access_token}`,
            }
        }
    )
        .then((res) => res.json())
};
