import {authenticatedRequest} from "../../../utils/axios/commonAxios";

export type GetBuddlistProps = {
    "message": string
    "email": string
    "phone": string
    "name": string
    "channel": "EMAIL" | "SMS" | 'APP' | 'WhatsApp'
}


export const transferToAdminrequeset = async <T>(id: string) => {

    return await authenticatedRequest<T>(
        `${process.env.REACT_APP_API_URL}/buddy/api/chat/users/${id}/transfer-to-admin`,
        "post",
        {}
    )
};

