import { Modal } from 'antd';
import * as React from 'react';
import { AiOutlineClose } from 'react-icons/ai';

const CommonModal = ({ component, title, visibility, setVisibility }) => {
  return (
    <>
      <Modal
        title={title}
        visible={visibility}
        onOk={() => setVisibility(!visibility)}
        onCancel={() => setVisibility(!visibility)}
        closeIcon={
          <AiOutlineClose
            onClick={() => setVisibility(!visibility)}
            style={{ color: 'black', width: 10, height: 10 }}
          />
        }
        footer={null}
        style={{ top: 40 }}
      >
        {component}
      </Modal>
    </>
  );
};

export default CommonModal;
