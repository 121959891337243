import { RegisterBuddyProps } from "../../../Models/RegisterBuddyProps";
import { CheckMobileProps, VerifyMobileProps } from "../../../Models/VerifyMobileProps";
import { authenticatedRequest } from "../../../utils/axios/commonAxios";

const axios = require("axios").default;

export const registerAPIRequest = async (user: RegisterBuddyProps) => {
  console.log("onboard Request", user);
  const data = await axios.post(
    `${process.env.REACT_APP_API_URL}/buddy/api/public/${user.publicId}/profile/onboard`,
    {
      ...user,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data.data;
};

export const fetchUniDataAPIRequest = async <T>() => {
  return await authenticatedRequest<T>(`${process.env.REACT_APP_API_URL}/buddy/api/university/`, "get", {});
};

export const checkMobileAPIRequest = async (mobileNo: CheckMobileProps) => {
  console.log(mobileNo.mobileNo);
  const data = await axios.post(`${process.env.REACT_APP_API_URL}/buddy/api/public/${mobileNo.publicId}/profile/otp`, {
    mobileNo: mobileNo.mobileNo,
  });
  return data.data;
};

export const verifyMobileAPIRequest = async (otp: VerifyMobileProps) => {
  const data = await axios.post(
    `${process.env.REACT_APP_API_URL}/buddy/api/public/${otp.publicId}/profile/otp/verify`,
    { otp: otp.otp }
  );
  return data.data;
};
