import {nonAuthenticatedRequest} from "../../utils/axios/commonAxios";
import {PersonalInformationProps} from "../../Models/PersonalInformationProps";

const axios = require('axios').default;

export const fetchUserAPIRequest = async (id: String) => {

    return await nonAuthenticatedRequest<PersonalInformationProps>(
        `${process.env.REACT_APP_API_URL}/buddy/api/public/${id}/check`,
        "get",
        {},
    )
};


