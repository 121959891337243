import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { Avatar, Col, Table, Typography, Tooltip } from "antd";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { getAllBuddyChatList, getLeaderBoardListSelector } from "./LeaderBoardSlice";

function LeaderBoard() {
  const dispatch = useAppDispatch();
  const { isFetching, leaderBoardList } = useAppSelector(getLeaderBoardListSelector);

  useEffect(() => {
    dispatch(getAllBuddyChatList());
  }, []);

  const columns = [
    {
      title: "#",
      dataIndex: "rank",
      key: "rank",
    },
    {
      dataIndex: ["imageUrl"],
      key: "imageUrl",
      align: "left" as "left",
      title: "Ambassador name",
      render: (imageUrl: string, record: any) => (
        <>
          <Avatar className="user_avatar" size="large" src={record?.imageUrl} />
          <span>{record?.name}</span>
        </>
      ),
    },
  ];

  return (
    <>
      <Col className="left_col">
        <Typography className="col_title">
          Ambassador Leaderboard
          {/* <Tooltip title="Ambassador Leader board based on their performance on the platform">
            <AiOutlineInfoCircle className="info_icon" />
          </Tooltip> */}
        </Typography>
        <div className="col_wrapper">
          {/* <Table
            className="leaderboard_table"
            dataSource={leaderBoardList}
            columns={columns}
            loading={isFetching}
            pagination={false}
          /> */}
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", top: "50", marginTop: '15%' }}>
            <p>Launching Soon</p>
          </div>
        </div>
      </Col>
    </>
  );
}

export default LeaderBoard;
