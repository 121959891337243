const axios = require('axios').default;

export const getFAQSummaryAPIRequest = async (token: string) => {
    const data = await axios.get(`${process.env.REACT_APP_API_URL}/buddy/api/settings/fq/summary`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    return data.data;
};

export const getFAQDetailsAPIRequest = async (req) => {
    const data = await axios.get(`${process.env.REACT_APP_API_URL}/buddy/api/settings/fq/${req.id}/details`, {
        headers: {
            'Authorization': `Bearer ${req.token}`
        }
    })
    return data.data;
};
