import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../hooks/localStoragehook/LocalStorageHook";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

const AuthContext = createContext({});

export type AuthPropType = {
  login?(loginResponse: any): {};
  user?: {
    access_token: string;
    expires_in: number;
    "not-before-policy": number;
    refresh_expires_in: number;
    refresh_token: string;
    scope: string;
    session_state: string;
    token_type: string;
  };
  logout?(): {};
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data) => {
    setUser(data);
    // if (data?.privateRouteDataUserId) {
    //   navigate(`/chat?user=${privateRouteDataUserId}`);
    // }
    // navigate("/");
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    navigate("/login", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
