import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import {
  UpdateInfoResponseProps,
  UpdatePersonalInfoProps,
  updateIntroVideoProps,
} from "../../../Models/UpdatePersonalInfoProps";
import { updateIntroVideoAPIRequest } from "./PersonalInformationAPI";

interface CounterState {
  isUpdatingIntroVideo: boolean;
  updateIntroVideoStatus: "IDLE" | "PENDING" | "SUCCESS" | "FAILED";
  updateIntroVideoResponse: {};
}

const initialState: CounterState = {
  isUpdatingIntroVideo: false,
  updateIntroVideoStatus: "IDLE",
  updateIntroVideoResponse: {},
};

export const updateIntroVideoRequest = createAsyncThunk(
  "update/updateIntroVideo",
  async (data: updateIntroVideoProps) => {
    return await updateIntroVideoAPIRequest(data);
  }
);

const updateIntroVideoSlice = createSlice({
  name: "updateIntroVideo",
  initialState: initialState,
  reducers: {
    clearIntroVideoUpdateResponse: (state) => {
      state.isUpdatingIntroVideo = false;
      state.updateIntroVideoStatus = "IDLE";
      state.updateIntroVideoResponse = {};
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(updateIntroVideoRequest.pending, (state) => {
        state.updateIntroVideoStatus = "PENDING";
        state.isUpdatingIntroVideo = true;
      })
      .addCase(updateIntroVideoRequest.fulfilled, (state, action) => {
        // Add user to the state array
        state.updateIntroVideoStatus = "SUCCESS";
        state.isUpdatingIntroVideo = false;
        state.updateIntroVideoResponse = action.payload;
      })
      .addCase(updateIntroVideoRequest.rejected, (state) => {
        state.updateIntroVideoStatus = "FAILED";
        state.isUpdatingIntroVideo = false;
      });
  },
});

export const updateIntroVideoSelector = (state: RootState) => state.updateIntroVideo;
export const { clearIntroVideoUpdateResponse } = updateIntroVideoSlice.actions;
export default updateIntroVideoSlice.reducer;
