import { Modal } from 'antd';
import './IntroVideoPreviewModal.scss';
import { useRef } from 'react';

export const IntroVideoPreviewModal = ({ visibleVideModal, handleVideoModalCancel, introVideoUrl }: any) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleCloseModal = () => {
    handleVideoModalCancel();
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };
  return (
    <Modal
      title={null}
      open={visibleVideModal}
      footer={null}
      onCancel={handleCloseModal}
      width={800}
      className="intro-video-modal"
    >
      {/* <div className="title">
        <Avatar className="profileImage" icon={<UserOutlined />} src={smallProfileUrl} />
        <span className="titleText">
          <h2>Get to know {firstName}, A warm introduction to who I am</h2>
          <h4 className="titleSubText">{programme}</h4>
        </span>
      </div> */}
      <video ref={videoRef} controls className="videoSection">
        <source src={introVideoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {/* <div className="footer">
        <Button type="text" size="large" onClick={handleVideoModalCancel}>
          Close
        </Button>
        <Button
          type="primary"
          size="large"
          className="questionButton"
          style={{
            backgroundColor: `${themeColor}`,
            borderColor: `${themeColor}`,
          }}
        >
          <div className="buttonContent">
            <div>
              <FaPaperPlane />
            </div>
            <div className="buttonText">Ask me question</div>
          </div>
        </Button>
      </div> */}
    </Modal>
  );
};
