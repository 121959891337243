import { useSelector } from 'react-redux';
import { RootState } from '../app/store';
import './globalLoader.scss';

const GlobalLoader = () => {
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);

  if (!isLoading) {
    return null;
  }

  return (
    <div
      className="page-loader"
      style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '999' }}
    >
      <div className="spinner"></div>
    </div>
  );
};

export default GlobalLoader;
