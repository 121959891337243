import { Col, Layout, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import CoverComponent from "../../components/CoverComponent/coverComponentV2";
import StatsScreen from "./statsScreen/StatsScreen";
import "./style.scss";
import Greeting from "../../utils/greetings/GreetUser";
import {
  fetchInformationSelector,
  fetchPersonalInformationRequest,
} from "../profile/personalInformation/PersonalInformationSlice";
import { Footer, Header } from "antd/lib/layout/layout";
import MainHeader from "../../layout/Header/MainHeader";
import LeaderBoardSection from "./LeaderBoardSection/LeaderBoardSection";
import { getAllBuddyChatList } from "../Chat/buddyList/BuddyListSlice";

import logo from "../../assets/images/logo.png";

const { Title } = Typography;
const MyDashboard = () => {
  const dispatch = useAppDispatch();
  let { informationResponse } = useAppSelector(fetchInformationSelector);

  useEffect(() => {
    dispatch(fetchPersonalInformationRequest());
  }, []);

  useEffect(() => {
    dispatch(getAllBuddyChatList());
  }, []);

  return (
    <>
      <Layout className="page_layout">
        <Content className="my_dashboard">
          <Row className="title_area">
            <Col className="col_left">
              <Title level={3}>My Dashboard</Title>
              <Greeting userName={informationResponse?.profile?.firstName ?? ""} />
            </Col>
          </Row>
        </Content>
        <Row className="gray_bg">
          <StatsScreen
            refLink={informationResponse?.profile?.refLink ?? ""}
            userName={informationResponse?.profile?.firstName ?? ""}
            buddyStatus={informationResponse?.status}
          />
        </Row>
        <LeaderBoardSection />
      </Layout>
      <Footer className="footer">
        <span>Powered by</span>
        <img src={logo} alt="Univiser Logo" />
      </Footer>
    </>
  );
};

export default MyDashboard;
